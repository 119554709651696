import { Box, Divider, Typography } from "@mui/material";
import React from "react";

import HomeIcon from '@mui/icons-material/Home';
import PlaceIcon from '@mui/icons-material/Place';
import PhoneIcon from '@mui/icons-material/Phone';
import theme from "../../utils/theme";

const shopInfoList = [
    {
        name: 'Super Mobile Mandalay',
        address: 'No.(B-1,2,3), 7th Street, Between 27th & 28th Streets, Chanayethazan Township, Mandalay.',
        phone: '09-423368881, 02-4066884'
    },
    {
        name: 'Super Mobile Yangon',
        address: 'Room (201-A1), No. (192), 2nd Floor, Myanmar Plaza, Kabaraye Pagoda Road, Yankin Township, Yangon',
        phone: '09-448490007, 09-452352555'
    }
]

const ShopInfo = () => {
    return (
        shopInfoList.map((shop, index) => (
            <Box
                key={index}
                sx={{
                    display: 'flex',
                    pb: '50px'
                }}
            >
                <Box>
                    <Box sx={{
                        display: 'flex'
                    }}>
                        <HomeIcon sx={{
                            color: theme.palette.primary.main,
                            mr: '10px'
                        }} />
                        <Typography sx={{
                            fontSize: theme.palette.Content.fontSize,
                            fontWeight: 'bold'
                        }}>{shop.name}</Typography>
                    </Box>
                    <Box height='10px' />
                    <Box sx={{
                        display: 'flex'
                    }}>
                        <PlaceIcon sx={{
                            color: theme.palette.primary.main,
                            mr: '10px'
                        }} />
                        <Typography>{shop.address}</Typography>
                    </Box>
                    <Box height='10px' />
                    <Box sx={{
                        display: 'flex'
                    }}>
                        <PhoneIcon sx={{
                            color: theme.palette.primary.main,
                            mr: '10px'
                        }} />
                        <Typography>{shop.phone}</Typography>
                    </Box>
                </Box>
                {
                    index < shopInfoList.length - 1 && ((
                        <Divider
                            orientation="vertical"
                            flexItem
                            sx={{
                                height: '100%',
                                mx: '5px',
                                bgcolor: '#4d4d4d',
                                borderRightWidth: 3
                            }}
                        />
                    ))
                }
            </Box>
        ))
    )
}

export default ShopInfo