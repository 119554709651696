import { Box, Link, List, ListItem, Typography } from "@mui/material";
import React from "react";
import theme from "../../utils/theme";
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TelegramIcon from '@mui/icons-material/Telegram';
import viperLogo from '../../assets/images/viper-logo.png';
import tiktokLogo from '../../assets/images/tiktok-logo.png';

const UsefulLink = () => {
    return (
        <Box>
            <Typography sx={{
                fontSize: theme.palette.SubTitle.fontSize,
                fontWeight: 'bold'
            }}>
                Useful Link
            </Typography>
            <List>
                <ListItem>
                    <Typography component="span" sx={{ color: 'red', marginRight: '10px' }}>●</Typography>
                    <Link href="/faq" sx={{ textDecoration: 'none', color: 'red' }}>FAQ</Link>
                </ListItem>
                <ListItem>
                    <Typography component="span" sx={{ color: 'red', marginRight: '10px' }}>●</Typography>
                    <Link href="/all-products" sx={{ textDecoration: 'none', color: 'red' }}>Products</Link>
                </ListItem>
                <ListItem>
                    <Typography component="span" sx={{ color: 'red', marginRight: '10px' }}>●</Typography>
                    <Link href="/tech-reviews" sx={{ textDecoration: 'none', color: 'red' }}>Tech Review</Link>
                </ListItem>
            </List>
            <Box sx={{
                display: 'flex'
            }}>
                <Link href="https://www.facebook.com/supermobileofficialpage" target="_blank" rel="noopener noreferrer">
                    <FacebookIcon sx={{ color: 'blue' }} />
                </Link>
                <Box width='10px' />
                <Link href="https://t.me/supermobilepage" target="_blank" rel="noopener noreferrer">
                    <TelegramIcon sx={{ color: '#4c8df5' }} />
                </Link>
                <Box width='10px' />
                <Link href="https://www.youtube.com/@supermobile_mm" target="_blank" rel="noopener noreferrer">
                    <YouTubeIcon sx={{ color: 'red' }} />
                </Link>
                <Box width='10px' />
                <Link href="https://www.tiktok.com/@supermobile.mm" target="_blank" rel="noopener noreferrer">
                    <img src={tiktokLogo} style={{ width: '20px', height: '20px' }} alt="Tiktok" />
                </Link>
                <Box width='10px' />
                <Link href="https://invite.viber.com/?g2=AQBhQOoLohPkglM7iA5KqUSAign2qR1M%2F5yWT%2Bjaa8TOpIngL0NPqpEikiwpJXd3" target="_blank" rel="noopener noreferrer">
                    <img src={viperLogo} style={{ width: '20px', height: '20px' }} alt="Viber" />
                </Link>
            </Box>


        </Box>
    )
}

export default UsefulLink